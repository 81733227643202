body { 
  .question--tool--first--div {
    .question-stepper-title {
      margin-bottom: 20px;
    }
    .symptomsDesc {
      padding-left: 5px;
    }
    .symptomsDesc:last-child {
      margin-bottom: 0;
    }
  }
  .question--tool--use--btn--div {
    margin-top: 20px;
    .screening--tool--myself--btn {
      margin-right: 15px;
    }
  }
  .screening--tool--label--section {
    label:last-child {
      margin-bottom: 0;
    }
  }
  .question--options--screening--tool {
    .Questions-margin_bottom-4:last-child {
      margin-bottom: 0;
    }
  }

.bs-stepper {
  .question--stepper--btn:hover {
    background-color: transparent;
  }
}
}
.question--checkbox--selected--color
{
  .Mui-checked
  {
      color: #006978;
  }
}

.question--screening--button {
  margin-top: 20px;
}