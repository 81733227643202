.your--order--bg {
  background-image: url("../../assets/img/order/order3.jpg");
  background-size : cover;
}

.your--order--list--title--div {
  text-align: center;
  margin    : 30px 0;

  .your--order--list--title {
    color      : #fff;
    font-size  : 36px;
    line-height: normal;
    font-weight: 300;
  }
}

.your--orders--section {
  border          : 0px solid #0000001f;
  background-color: #fff;
  padding         : 20px 15px;

  .your--orders--title {
    font-weight: bold;
    font-size  : 22px;
    color      : #000000e3;
  }

  .orders--title--section {
    .orders--title--section--container {
      background-color: #f0f0f0;
      margin          : 0;
      padding         : 14px 0;

      .order--item--title--grid,
      .order--name--title--grid,
      .order--price--title--grid,
      .order--qty--title--grid,
      .order--total--title--grid {
        display        : flex;
        justify-content: center;
      }

      .order--item--title,
      .order--name--title,
      .order--price--title,
      .order--qty--title,
      .order--total--title {
        font-weight: 400;
      }
    }
  }

  .orders--section {
    max-height: 423px;
    overflow-y: auto;

    .orders--section--container {
      padding      : 20px 0 20px;
      margin       : 0;
      border-bottom: 1px solid #0000000f;

      .orders--img--grid {
        padding        : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;

        .orders--img {
          .lazy-load-image-background {
            width : 110px;
            height: 100px;

            img {
              width : 100%;
              height: 100%;
            }
          }
        }
      }

      .orders--name--grid {
        display        : flex;
        align-items    : center;
        justify-content: center;

        span {
          font-weight: 600;
        }
      }

      .orders--price--grid {
        display        : flex;
        align-items    : center;
        justify-content: center;

        span {
          font-weight: 600;
        }
      }

      .orders--qty--grid {
        display        : flex;
        align-items    : center;
        justify-content: center;
        flex-direction : column;

        .qty--update--text {
          color      : #006878;
          padding-top: 5px;
        }

        .qty--remove--btn {
          border       : 1px solid #00000026;
          border-radius: 30%;
          padding      : 7px;
          margin-right : 16px;

          svg {
            margin: 0;
            color : #0000007a;
            width : 15px;
            height: 15px;
          }
        }

        .qty--add--btn {
          border       : 1px solid #00000026;
          border-radius: 30%;
          padding      : 7px;
          margin-left  : 16px;

          svg {
            margin: 0;
            color : #0000007a;
            width : 15px;
            height: 15px;
          }
        }

        span {
          font-weight: 600;
        }
      }

      .orders--total--grid {
        display        : flex;
        align-items    : center;
        justify-content: center;

        span {
          font-weight: 600;
          color      : #ed383c;
        }
      }

      .orders--remove--grid {
        display        : flex;
        align-items    : center;
        justify-content: center;

        span {
          font-weight: 600;
        }

        .order--delete--btn {
          svg {
            width : 22px;
            height: 22px;
          }
        }
      }
    }
  }

  .order--total--section {
    .order--total--section--container {
      border-bottom: 1px solid #0000000f;
      padding      : 30px 0;

      .order--main--total--section {
        padding-right: 40px;

        .order--sub--title--div {
          display        : flex;
          justify-content: flex-end;

          @media(max-width: 768px) {
            justify-content: flex-start;
          }

          .order--sub--total--title {
            font-size      : 14px;
            font-weight    : 600;
            color          : #858585;
            display        : flex;
            justify-content: flex-end;
            line-height    : 1.5;
            align-items    : center;
          }

          .order--sub--total--first--amount,
          .order--sub--total--second--amount {
            display     : flex;
            margin-left : 0;
            min-width   : 13%;
            padding-left: 20px;
            color       : #000;
            font-size   : 16px;
            line-height : 1.5;
            align-items : center;
          }
        }

        .order--shipping--title--div {
          display        : flex;
          justify-content: flex-end;

          @media(max-width: 768px) {
            justify-content: flex-start;
          }

          .order--shipping--title {
            font-size      : 14px;
            font-weight    : 500;
            color          : #9e9e9ed6;
            display        : flex;
            justify-content: flex-end;
            line-height    : 1.5;
            align-items    : center;
          }

          .order--shipping--amount {
            display     : flex;
            margin-left : 0;
            min-width   : 13%;
            padding-left: 20px;
            color       : #9e9e9ef0;
            font-size   : 16px;
            font-weight : 500;
            line-height : 1.5;
            align-items : center;
          }
        }

        .order--total--amount--title--div {
          display        : flex;
          justify-content: flex-end;

          @media(max-width: 768px) {
            justify-content: flex-start;
          }

          .order--total--amount--title {
            font-size      : 19px;
            font-weight    : 500;
            color          : #000;
            display        : flex;
            justify-content: flex-end;
            line-height    : 2;
            align-items    : center;
          }

          .order--total--first--amount,
          .order--total--second--amount {
            display     : flex;
            margin-left : 0;
            min-width   : 13%;
            padding-left: 20px;
            line-height : 2;
            align-items : center;
            font-size   : 22px;
            color       : #ed383c;
          }
        }
      }
    }
  }

  .order--promo--code--place--section {
    margin: 20px 0 10px;

    .promo--code--grid {
      padding: 0;

      .order--promo--code--section {
        display    : flex;
        align-items: center;

        .promo--code--text--field--div {
          .MuiFormControl-root {
            margin: 0;
          }

          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            background-color: #fff;
            padding         : 0 10px;
          }
        }

        .promo--code--apply--btn {
          margin-top   : 0;
          margin-bottom: 0;
          margin-left  : 15px;
          border-radius: 4px !important;
        }
      }
    }

    .order--place--cancel--btn--grid {
      display        : flex;
      justify-content: flex-end;
      padding        : 0;
      align-items    : center;

      .order--cancel--btn {
        border          : 1px solid;
        border-width    : 2px;
        color           : #006878;
        font-size       : 14px;
        font-weight     : bold;
        background-color: #fff;
        font-weight     : 600;
        border-radius   : 30px !important;

        &:hover {
          background-color: #006878;
          color           : #fff;
          border-color    : #006878;
        }
      }

      .place--order--btn {
        border-radius: 4px !important;
        font-size    : 14px;
        font-weight  : bold;
        padding      : 8px 25px;
        margin-left  : 8px;
      }
    }
  }
}

/* width */
.orders--section::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.orders--section::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
.orders--section::-webkit-scrollbar-thumb {
  background   : #00687847;
  border-radius: 6px;
}

/* Handle on hover */
.orders--section::-webkit-scrollbar-thumb:hover {
  background: #00687847;
}

.your--order--mobile--section {
  .your--orders--section {
    .orders--section {
      .orders--section--container {
        box-shadow   : 5px 6px 8px 1px #00000024;
        border       : 1px solid #0000001f;
        border-radius: 4px;
        margin-bottom: 20px;
        margin-left  : 10px;
        margin-right : 10px;

        .orders--img--grid {
          .orders--img {
            .lazy-load-image-background {
              width : 100px;
              height: 90px;
            }
          }
        }

        .orders--qty--grid {
          flex-direction : row;
          justify-content: flex-start;
          margin         : 10px 0;

          .qty--update--text {
            margin-right: 8px;
          }

          .update--qty--num--div {
            .qty--add--btn {
              padding    : 4px;
              margin-left: 10px;
            }

            .qty--remove--btn {
              padding     : 4px;
              margin-right: 10px;
            }
          }
        }

        .orders--remove--grid {
          margin         : 10px 0;
          display        : flex;
          justify-content: center;
          align-items    : center;

          .order--delete--btn {
            svg {
              width : 26px;
              height: 26px;
            }
          }
        }

        .order--name--price--total--grid {
          display        : flex;
          flex-direction : column;
          justify-content: center;
          padding-left   : 0;

          .orders--name--grid,
          .orders--price--grid,
          .orders--total--grid {
            justify-content: flex-start;
          }

          .orders--name--grid,
          .orders--price--grid {
            margin-bottom: 5px;
          }

          .orders--price--grid {
            color: #9e9e9e;
          }
        }
      }
    }
  }
}

.your--order--tablet--div {
  .your--orders--section {
    .orders--section {
      .orders--section--container {
        .orders--qty--grid {
          padding: 0;

          .qty--remove--btn {
            padding     : 4px;
            margin-right: 10px;
          }

          .qty--add--btn {
            padding    : 4px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 320px) and (orientation: portrait) {
  body {
    .your--order--mobile--section {
      .your--orders--section {
        .order--total--section {
          .order--promo--code--place--section {
            .promo--code--grid {
              .order--promo--code--section {
                .promo--code--apply--btn--div {
                  min-width: 35%;

                  button {
                    padding  : 12px 20px !important;
                    font-size: 12px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// mobile portrait
@media only screen and (min-device-width: 320px) and (orientation: portrait) {
  body {
    .your--order--mobile--section {
      .order--total--section {
        .order--total--section--container {
          padding: 20px 0;

          .order--main--total--section {
            padding: 0 !important;

            .order--sub--title--div {

              .order--sub--total--first--amount,
              .order--sub--total--second--amount {
                min-width   : 60%;
                padding-left: 12px;
              }
            }

            .order--shipping--title--div {

              .order--shipping--amount {
                min-width   : 60%;
                padding-left: 12px;
              }
            }

            .order--total--amount--title--div {
              .order--total--amount--title {
                line-height: 1.5;
                font-size  : 17px;
              }

              .order--total--first--amount,
              .order--total--second--amount {
                min-width   : 60%;
                line-height : 1.5;
                font-size   : 17px;
                padding-left: 12px;
              }
            }
          }
        }
      }

      .your--order--place--order--div {
        display      : flex;
        align-items  : center;
        margin-bottom: 10px;

        .order--place--cancel--btn--grid {
          .place--order--btn {
            border-radius: 4px !important;
            font-size    : 14px;
            font-weight  : bold;
            padding      : 8px 25px;
            margin-left  : 8px;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 375px) and (orientation: portrait) {
  body {
    .your--order--mobile--section {
      .your--orders--section {
        .your--orders--title {
          font-size: 19px;
        }

        .orders--section {
          .orders--section--container {
            padding: 5px 0 5px;

            .orders--img--grid {
              .orders--img {
                .lazy-load-image-background {
                  width : 80px;
                  height: 75px;
                }
              }
            }

            .orders--qty--grid {
              .qty--update--text {
                font-size: 14px;
              }
            }

            .order--name--price--total--grid {

              .orders--name--grid,
              .orders--price--grid,
              .orders--total--grid {
                span {
                  font-size: 14px;
                }
              }

              .orders--name--grid,
              .orders--price--grid {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      .your--order--place--order--div {
        .order--place--cancel--btn--grid {
          .place--order--btn {
            padding  : 8px 16px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 568px) and (orientation: landscape) {
  body {
    .your--order--mobile--section {
      .your--orders--section {
        .your--order--place--order--div {
          align-items  : center;
          margin-bottom: 10px;

          .order--place--cancel--btn--grid {
            display        : flex;
            justify-content: flex-end;

            button {
              background: -webkit-linear-gradient(left,
                  #08525e 0%,
                  #0097a7c4 100%) !important;
            }
          }
        }
      }

      .order--total--section {
        .order--total--section--container {
          padding: 15px 0;

          .order--main--total--section {
            padding: 0 !important;

            .order--sub--title--div {

              .order--sub--total--first--amount,
              .order--sub--total--second--amount {
                min-width   : 70%;
                padding-left: 12px;
              }
            }

            .order--shipping--title--div {

              .order--shipping--amount {
                min-width   : 70%;
                padding-left: 12px;
              }
            }

            .order--total--amount--title--div {
              .order--total--amount--title {
                line-height: 1.5;
                font-size  : 17px;
              }

              .order--total--first--amount,
              .order--total--second--amount {
                min-width   : 70%;
                line-height : 1.5;
                font-size   : 17px;
                padding-left: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 812px) and (orientation: landscape) {
  body {
    .your--order--mobile--section {
      .your--orders--section {
        .orders--section {
          .orders--section--container {
            .orders--img--grid {
              max-width : 25%;
              flex-basis: 25%;
            }

            .order--name--price--total--grid {
              max-width : 33.333333%;
              flex-basis: 33.333333%;
            }

            .orders--qty--grid {
              max-width : 33.333333%;
              flex-basis: 33.333333%;
            }

            .orders--remove--grid {
              max-width : 8.333333%;
              flex-basis: 8.333333%;
            }
          }
        }
      }

      .order--total--section {
        .order--total--section--container {
          .order--main--total--section {
            padding: 0 !important;

            .order--sub--title--div {

              .order--sub--total--first--amount,
              .order--sub--total--second--amount {
                min-width: 80%;
              }
            }

            .order--shipping--title--div {

              .order--shipping--amount {
                min-width: 80%;
              }
            }

            .order--total--amount--title--div {

              .order--total--first--amount,
              .order--total--second--amount {
                min-width: 80%;
              }
            }
          }
        }
      }
    }
  }
}

.button--left {
  text-align: right;
}

.mobile--cart--order--details {
  box-shadow   : none !important;
  margin-left  : 0 !important;
  margin-right : 0 !important;
  border-radius: 0 !important;
}

.mobile--custom--qty--grid {
  margin    : 0 !important;
  margin-top: 5px !important;

}

.mobile--subtotal--title,
.mobile--total--amount--title {
  color      : #455a64;
  font-weight: 500;
}

.mobile--shipping--charges--section {
  color: #9e9e9e;
}

.mobile--order--total--div {
  span {
    color: #455a64 !important;
  }
}

.mobile--order--delete--btn  {
  svg {
    color: #ed383c !important;
  }
}

.mobile--order--promo--code--section {
  justify-content: space-between;
}