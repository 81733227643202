.payment--gateway {
  background-image: url("../../assets/img/payment/payment-bg1.jpg");
  background-size : cover;
  padding-top     : 125px;

  .payment--gateway--section {
    min-height     : 100vh;
    display        : flex;
    justify-content: center;

    .payment--section {
      width           : 100%;
      padding-top     : 0;
      box-shadow      : 0 0 19px 8px #888888a3;
      border          : 0px solid #0000001f;
      background-color: #fff;

      .payment--left--div {
        width   : 33%;
        position: absolute;
        height  : 100vh;

        .lazy-load-image-background {
          width : 100%;
          height: 100vh;

          img {
            width     : 100%;
            height    : 100%;
            object-fit: cover;
          }
        }
      }

      .payment--right--div {
        width           : 67%;
        background-image: url("../../assets/img/payment/payment-bg1.jpg");
        background-size : cover;
        // position: relative;
        // height: auto;
        margin-left     : 33%;
        overflow        : hidden;

        .payment--btn--option--section {
          display           : flex;
          flex-direction    : column;
          // justify-content: center;
          height            : 100%;
          padding           : 100px 60px;

          .payment--title--div {
            display       : flex;
            flex-direction: column;

            .payment--info--title {
              color        : #0096a7;
              font-weight  : 500;
              text-shadow  : 1px 1px 1px #0000008a;
              font-size    : 36px;
              margin-bottom: 33px;
            }

            .payment--info--sub--title {
              color        : #3c5d6e;
              font-size    : 22px;
              font-weight  : 400;
              text-shadow  : 1px 1px 0px #50616985;
              margin-bottom: 33px;
            }
          }

          .paypal--stripe--tabs--div {
            min-height: auto;
            width     : 100%;

            .MuiTabs-flexContainer {
              .paypal--main--btn {
                margin-right: 20px;
              }
            }
          }
        }

        .paypal--stripe--form--section {
          height         : 100%;
          display        : flex;
          align-items    : center;
          justify-content: center;
          flex-direction : column;
          overflow-y     : auto;
          position       : relative;

          .payment--back--div {
            color      : #006978;
            font-weight: 500;
            cursor     : pointer;
            display    : flex;
            align-items: center;
            width      : fit-content;
            position   : absolute;
            top        : 10px;
            left       : 10px;

            .lazy-load-image-background {
              width       : 24px;
              height      : 24px;
              margin-right: 10px;
              display     : flex !important;

              img {
                height: 100%;
                width : 100%;
              }
            }

            span {
              font-size: 19px;
            }
          }

          .paypal--options--div {
            width          : 40%;
            height         : 100%;
            display        : flex;
            align-items    : center;
            justify-content: center;
            position       : relative;

            &>div {
              padding      : 0;
              width        : 100%;
              top          : 10px;
              margin-bottom: 30px;

              .paypal-button-number-0 {
                margin-bottom: 20px;
              }
            }
          }

          #vertical-tabpanel-1 {
            position: absolute;

            .StripeCheckout {
              height       : auto;
              line-height  : normal;
              min-height   : auto;
              margin       : 0;
              border-radius: 5px !important;
              font-size    : 16px;
              font-weight  : bold;
              padding      : 16px 25px !important;
              color        : #fff;
              border       : 0px solid #0097a7;
              background   : -webkit-linear-gradient(left,
                  #08525e 0%,
                  #0097a7c4 100%) !important;
              opacity  : 1;
              width    : 100%;
              max-width: unset;

              span {
                height     : auto !important;
                line-height: normal !important;
                font-size  : 16px !important;
              }
            }
          }
        }
      }
    }
  }
}

/* width */
.paypal--stripe--form--section::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.paypal--stripe--form--section::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
.paypal--stripe--form--section::-webkit-scrollbar-thumb {
  background   : #00687847;
  border-radius: 6px;
}

/* Handle on hover */
.paypal--stripe--form--section::-webkit-scrollbar-thumb:hover {
  background: #00687847;
}

.payment--tablet--gateway.payment--gateway {
  padding-top: 95px;

  .payment--gateway--section {
    .payment--section {
      flex-direction: column;

      .payment--left--div {
        width   : 100%;
        position: unset;
        height  : 50vh;

        .lazy-load-image-background {
          height: 50vh;
        }
      }

      .payment--right--div {
        width: 100%;

        // left: 0;
        .paypal--stripe--form--section {
          .paypal--options--div>div {
            top     : 50px;
            position: absolute;
          }
        }
      }
    }
  }
}

.mobile--payment--main--section {
  .payment--gateway {
    padding-top: 57px;

    .payment--gateway--section {
      padding: 20px 10px;

      .payment--section {
        flex-direction: column;
        max-height    : 120vh;

        .payment--left--div {
          width   : 100%;
          position: relative;
          height  : 50vh;

          .lazy-load-image-background {
            height: 50vh;
          }
        }

        .payment--right--div {
          width: 100%;

          // left: 0;
          .payment--btn--option--section {
            padding: 25px;

            .paypal--stripe--tabs--div {
              overflow: visible;
            }
          }

          .paypal--stripe--form--section {
            #vertical-tabpanel-0 {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

// ipad landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (min-height: 768px) and (max-height: 786px) and (orientation: landscape) {
  .payment--tablet--gateway.payment--gateway {
    padding-top: 95px;

    .payment--gateway--section {
      .payment--section {
        flex-direction: row;

        .payment--left--div {
          width   : 40%;
          position: absolute;
          height  : 100vh;

          .lazy-load-image-background {
            height: 100vh;
          }
        }

        .payment--right--div {
          width      : 60%;
          margin-left: 40%;

          .paypal--stripe--form--section {
            .paypal--options--div>div {
              top     : 50px;
              position: absolute;
            }
          }

          .payment--btn--option--section {
            .paypal--stripe--tabs--div {
              .MuiTabs-flexContainer {

                .paypal--main--btn,
                .stripe--main--btn {
                  padding: 14px 70px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//iPad Pro Portrait
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .payment--tablet--gateway.payment--gateway {
    padding-top: 95px;

    .payment--gateway--section {
      min-height: 80vh;

      .payment--section {
        flex-direction: row;
        max-height    : 80vh;

        .payment--left--div {
          width   : 40%;
          position: absolute;
          height  : 80vh;

          .lazy-load-image-background {
            height: 80vh;
          }
        }

        .payment--right--div {
          width      : 60%;
          margin-left: 40%;

          .paypal--stripe--form--section {
            .paypal--options--div>div {
              top     : 50px;
              position: absolute;
            }
          }

          .payment--btn--option--section {
            .paypal--stripe--tabs--div {
              .MuiTabs-flexContainer {

                .paypal--main--btn,
                .stripe--main--btn {
                  padding: 14px 70px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ipad pro landscape

@media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (min-height: 1024px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .payment--tablet--gateway.payment--gateway {
    padding-top: 125px;

    .payment--gateway--section {
      min-height: 80vh;

      .payment--section {
        flex-direction: row;
        max-height    : 80vh;

        .payment--left--div {
          width   : 40%;
          position: absolute;
          height  : 80vh;

          .lazy-load-image-background {
            height: 80vh;
          }
        }

        .payment--right--div {
          width: 60%;

          // left: 40%;
          .paypal--stripe--form--section {
            .paypal--options--div>div {
              top     : 50px;
              position: absolute;
            }
          }

          .payment--btn--option--section {
            .paypal--stripe--tabs--div {
              .MuiTabs-flexContainer {

                .paypal--main--btn,
                .stripe--main--btn {
                  padding: 14px 70px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 568px) and (orientation: landscape) {
  .mobile--payment--main--section {
    .payment--gateway {
      .payment--gateway--section {
        .payment--section {
          max-height: 160vh;

          .payment--left--div {
            height: 70vh;

            .lazy-load-image-background {
              height: 70vh;
            }
          }

          .payment--right--div {
            height: 90vh;

            .paypal--stripe--form--section {
              #vertical-tabpanel-0 {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 360px) and (orientation: portrait) {
  .mobile--payment--main--section {
    .payment--gateway {
      .payment--gateway--section {
        .payment--section {
          .payment--right--div {
            .payment--btn--option--section {
              .payment--title--div {
                .payment--info--title {
                  font-size    : 30px;
                  margin-bottom: 20px;
                }

                .payment--info--sub--title {
                  font-size    : 19px;
                  margin-bottom: 20px;
                }
              }


              .paypal--stripe--tabs--div {
                .MuiTabs-flexContainer {
                  flex-direction: column;

                  .paypal--main--btn,
                  .stripe--main--btn {
                    padding: 14px 70px;
                  }

                  .paypal--main--btn {
                    margin-right : 0;
                    margin-bottom: 20px;
                  }
                }
              }
            }

            .paypal--stripe--form--section {
              #vertical-tabpanel-0 {
                position: absolute;
                top     : 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 568px) and (orientation: landscape) {
  .mobile--payment--main--section {
    .payment--gateway {
      .payment--gateway--section {
        .payment--section {
          .payment--right--div {
            .payment--btn--option--section {
              .payment--title--div {
                .payment--info--title {
                  font-size    : 30px;
                  margin-bottom: 20px;
                }

                .payment--info--sub--title {
                  font-size    : 19px;
                  margin-bottom: 20px;
                }
              }


              .paypal--stripe--tabs--div {
                .MuiTabs-flexContainer {

                  .paypal--main--btn,
                  .stripe--main--btn {
                    padding: 14px 80px;
                  }
                }
              }
            }

            .paypal--stripe--form--section {
              #vertical-tabpanel-0 {
                position: absolute;
                top     : 25px;
              }
            }
          }
        }
      }
    }
  }
}

button.paypal--main--button,
button.stripe--main--button {
  padding      : 30px !important;
  font-size    : 18px !important;
  border-radius: 0 !important;
}

.payment--buttons {
  margin-top: 50px;
}