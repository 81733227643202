body {
  .dive--deeper--title{
    font-weight: bold;
    color: #006978;
    margin: 30px 0;
    text-transform: capitalize;
    text-align: center;
  }  
  .blood--samples--title,
  .how--it--works--title {
    font-weight: bold;
    color: #006978;
    margin: 30px 0;
    margin-left: auto;
    margin-right: auto;
  }
  .how--it--works--title--section {
    display: flex;
    flex-direction: column;
  }
  .order--kit--bg,
  .blood--sample--bg,
  .result--bg {
    background-color: rgba(39, 209, 201, 0.03);
    // background-color: #1bc8e20d;
  }
  .order--kit--div--spacing {
    display: flex;
    padding: 3rem 0;
    .order--kit--first--section--grid {
      .order--kit--first--section {
        .order--kit--left--div {
          display: flex;
          .order--kit--number--section {
            // position: relative;
            .order--kit--number--div {
              // position: relative;
              .order--kit--number {
                font-size: 2.5rem;
                width: 4.375rem;
                height: 4.375rem;
                line-height: 4.37rem;
                margin-top: -0.5rem;
                margin-left: -1rem;
                display: block;
                text-align: center;
                font-weight: 300;
                color: #00b1c2;
                border: 1px solid #27d1c9;
                border-radius: 50%;
              }
            }
          }
          .order--kit--number--div::after {
            height: calc(100% + 27px);
            content: "";
            width: 1px;
            background-image: url(../../assets/img/work/dot.png);
            background-repeat: repeat-y;
            background-size: 0.3rem auto;
            position: absolute;
            left: 35px;
            display: block;
          }
          .order--kit--title--div {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .order--kit--title {
              color: #213f66;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
              line-height: 1.2;
            }
            .order--kit--desc {
              font-size: 22px;
              font-weight: 300;
              color: #677085c4;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .order--kit--right--img--grid {
      .order--kit--right--img {
        .lazy-load-image-background {
          height: 270px;
          width: 270px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .material--delivery--div--spacing {
    display: flex;
    padding: 3rem 0;
    .material--delivery--first--section--grid {
      .material--delivery--first--section {
        .material--delivery--left--div {
          display: flex;
          .material--delivery--number--section {
            // position: relative;
            .material--delivery--number--div {
              // position: relative;
              .material--delivery--number {
                font-size: 2.5rem;
                width: 4.375rem;
                height: 4.375rem;
                line-height: 4.37rem;
                margin-top: -0.5rem;
                margin-left: -1rem;
                display: block;
                text-align: center;
                font-weight: 300;
                color: #00b1c2;
                border: 1px solid #27d1c9;
                border-radius: 50%;
              }
            }
          }
          .material--delivery--number--div::before {
            top: -12px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #71e0eb;
            position: absolute;
            left: 30px;
            display: block;
          }
          .material--delivery--number--div::after {
            height: calc(100% + 27px);
            content: "";
            width: 1px;
            background-image: url(../../assets/img/work/dot.png);
            background-repeat: repeat-y;
            background-size: 0.3rem auto;
            position: absolute;
            left: 35px;
            display: block;
          }
          .material--delivery--title--div {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .material--delivery--title {
              color: #213f66;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
              line-height: 1.2;
            }
            .material--delivery--desc {
              font-size: 22px;
              font-weight: 300;
              color: #677085c4;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .material--delivery--right--img--grid {
      .material--delivery--right--img {
        .lazy-load-image-background {
          height: 270px;
          width: 270px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .blood--sample--div--spacing {
    display: flex;
    padding: 3rem 0;
    .blood--sample--first--section--grid {
      .blood--sample--first--section {
        .blood--sample--left--div {
          display: flex;
          .blood--sample--number--section {
            // position: relative;
            .blood--sample--number--div {
              // position: relative;
              .blood--sample--number {
                font-size: 2.5rem;
                width: 4.375rem;
                height: 4.375rem;
                line-height: 4.37rem;
                margin-top: -0.5rem;
                margin-left: -1rem;
                display: block;
                text-align: center;
                font-weight: 300;
                color: #00b1c2;
                border: 1px solid #27d1c9;
                border-radius: 50%;
              }
            }
          }
          .blood--sample--number--div::before {
            top: -12px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #71e0eb;
            position: absolute;
            left: 30px;
            display: block;
          }
          .blood--sample--number--div::after {
            height: calc(100% + 27px);
            content: "";
            width: 1px;
            background-image: url(../../assets/img/work/dot.png);
            background-repeat: repeat-y;
            background-size: 0.3rem auto;
            position: absolute;
            left: 35px;
            display: block;
          }
          .blood--sample--title--div {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .blood--sample--title {
              color: #213f66;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
              line-height: 1.2;
            }
            .blood--sample--desc {
              font-size: 22px;
              font-weight: 300;
              color: #677085c4;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .blood--sample--right--img--grid {
      .blood--sample--right--img {
        .lazy-load-image-background {
          height: 270px;
          width: 270px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .test--laboratory--div--spacing {
    display: flex;
    padding: 3rem 0;
    .test--laboratory--first--section--grid {
      .test--laboratory--first--section {
        .test--laboratory--left--div {
          display: flex;
          .test--laboratory--number--section {
            // position: relative;
            .test--laboratory--number--div {
              // position: relative;
              .test--laboratory--number {
                font-size: 2.5rem;
                width: 4.375rem;
                height: 4.375rem;
                line-height: 4.37rem;
                margin-top: -0.5rem;
                margin-left: -1rem;
                display: block;
                text-align: center;
                font-weight: 300;
                color: #00b1c2;
                border: 1px solid #27d1c9;
                border-radius: 50%;
              }
            }
          }
          .test--laboratory--number--div::before {
            top: -12px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #71e0eb;
            position: absolute;
            left: 30px;
            display: block;
          }
          .test--laboratory--number--div::after {
            height: calc(100% + 27px);
            content: "";
            width: 1px;
            background-image: url(../../assets/img/work/dot.png);
            background-repeat: repeat-y;
            background-size: 0.3rem auto;
            position: absolute;
            left: 35px;
            display: block;
          }
          .test--laboratory--title--div {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .test--laboratory--title {
              color: #213f66;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
              line-height: 1.2;
            }
            .test--laboratory--desc {
              font-size: 22px;
              font-weight: 300;
              color: #677085c4;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .test--laboratory--right--img--grid {
      .test--laboratory--right--img {
        .lazy-load-image-background {
          height: 270px;
          width: 270px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .result--div--spacing {
    display: flex;
    padding: 3rem 0;
    .result--first--section--grid {
      .result--first--section {
        .result--left--div {
          display: flex;
          .result--number--section {
            // position: relative;
            .result--number--div {
              // position: relative;
              .result--number {
                font-size: 2.5rem;
                width: 4.375rem;
                height: 4.375rem;
                line-height: 4.37rem;
                margin-top: -0.5rem;
                margin-left: -1rem;
                display: block;
                text-align: center;
                font-weight: 300;
                color: #00b1c2;
                border: 1px solid #27d1c9;
                border-radius: 50%;
              }
            }
          }
          .result--number--div::before {
            top: -12px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #71e0eb;
            position: absolute;
            left: 30px;
            display: block;
          }
          .result--number--div::after {
            height: calc(100% - 14px);
            content: "";
            width: 1px;
            background-image: url(../../assets/img/work/dot.png);
            background-repeat: repeat-y;
            background-size: 0.3rem auto;
            position: absolute;
            left: 35px;
            display: block;
          }
          .result--title--div {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .result--title {
              color: #213f66;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
              line-height: 1.2;
            }
            .result--desc {
              font-size: 22px;
              font-weight: 300;
              color: #677085c4;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .result--right--img--grid {
      .result--right--img {
        .lazy-load-image-background {
          height: 270px;
          width: 270px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .tab--order--kit--bg {
    border-bottom: 1px dashed #00b1c2;
    .order--kit--div--spacing {
      flex-direction: column;
      .order--kit--first--section--grid {
        .order--kit--first--section {
          .order--kit--left--div {
            .order--kit--number--div::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .tab--material--delivery--bg {
    border-bottom: 1px dashed #00b1c2;
    .material--delivery--div--spacing {
      flex-direction: column;
      .material--delivery--first--section--grid {
        .material--delivery--first--section {
          .material--delivery--left--div {
            .material--delivery--number--div::after,
            .material--delivery--number--div::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .tab--blood--sample--bg {
    border-bottom: 1px dashed #00b1c2;
    .blood--sample--div--spacing {
      flex-direction: column;
      .blood--sample--first--section--grid {
        .blood--sample--first--section {
          .blood--sample--left--div {
            .blood--sample--number--div::after,
            .blood--sample--number--div::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .tab--test--laboratory--bg {
    border-bottom: 1px dashed #00b1c2;
    .test--laboratory--div--spacing {
      flex-direction: column;
      .test--laboratory--first--section--grid {
        .test--laboratory--first--section {
          .test--laboratory--left--div {
            .test--laboratory--number--div::after,
            .test--laboratory--number--div::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .tab--result--bg {
    border-bottom: 1px dashed #00b1c2;
    .result--div--spacing {
      flex-direction: column;
      .result--first--section--grid {
        .result--first--section {
          .result--left--div {
            .result--number--div::after,
            .result--number--div::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .how--it--works--mobile--section {
    .order--kit--bg {
      border-bottom: 1px dashed #00b1c2;
      .order--kit--div--spacing {
        flex-direction: column;
        .order--kit--first--section--grid {
          display: table;
          .order--kit--first--section {
            .order--kit--left--div {
              .order--kit--number--section {
                .order--kit--number--div {
                  .order--kit--number {
                    font-size: 2rem;
                    width: 3.375rem;
                    height: 3.375rem;
                    line-height: 3.37rem;
                    margin-top: -0.5rem;
                    margin-left: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    color: #00b1c2;
                    border: 1px solid #27d1c9;
                    border-radius: 50%;
                  }
                }
              }
              .order--kit--title--div {
                padding-left: 10px;
                padding-right: 10px;
              }
              .order--kit--number--div::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .material--delivery--bg {
      border-bottom: 1px dashed #00b1c2;
      .material--delivery--div--spacing {
        flex-direction: column;
        .material--delivery--first--section--grid {
          display: table;
          .material--delivery--first--section {
            .material--delivery--left--div {
              .material--delivery--number--section {
                .material--delivery--number--div {
                  .material--delivery--number {
                    font-size: 2rem;
                    width: 3.375rem;
                    height: 3.375rem;
                    line-height: 3.37rem;
                    margin-top: -0.5rem;
                    margin-left: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    color: #00b1c2;
                    border: 1px solid #27d1c9;
                    border-radius: 50%;
                  }
                }
              }
              .material--delivery--title--div {
                padding-left: 10px;
                padding-right: 10px;
              }
              .material--delivery--number--div::after,
              .material--delivery--number--div::before {
                display: none;
              }
            }
          }
        }
      }
    }
    .blood--sample--bg {
      border-bottom: 1px dashed #00b1c2;
      .blood--sample--div--spacing {
        flex-direction: column;
        .blood--sample--first--section--grid {
          display: table;
          .blood--sample--first--section {
            .blood--sample--left--div {
              .blood--sample--number--section {
                .blood--sample--number--div {
                  .blood--sample--number {
                    font-size: 2rem;
                    width: 3.375rem;
                    height: 3.375rem;
                    line-height: 3.37rem;
                    margin-top: -0.5rem;
                    margin-left: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    color: #00b1c2;
                    border: 1px solid #27d1c9;
                    border-radius: 50%;
                  }
                }
              }
              .blood--sample--title--div {
                padding-left: 10px;
                padding-right: 10px;
              }
              .blood--sample--number--div::after,
              .blood--sample--number--div::before {
                display: none;
              }
            }
          }
        }
      }
    }
    .test--laboratory--bg {
      border-bottom: 1px dashed #00b1c2;
      .test--laboratory--div--spacing {
        flex-direction: column;
        .test--laboratory--first--section--grid {
          .test--laboratory--first--section {
            .test--laboratory--left--div {
              .test--laboratory--number--section {
                .test--laboratory--number--div {
                  .test--laboratory--number {
                    font-size: 2rem;
                    width: 3.375rem;
                    height: 3.375rem;
                    line-height: 3.37rem;
                    margin-top: -0.5rem;
                    margin-left: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    color: #00b1c2;
                    border: 1px solid #27d1c9;
                    border-radius: 50%;
                  }
                }
              }
              .test--laboratory--title--div {
                padding-left: 10px;
                padding-right: 10px;
              }
              .test--laboratory--number--div::after,
              .test--laboratory--number--div::before {
                display: none;
              }
            }
          }
        }
      }
    }
    .result--bg {
      border-bottom: 1px dashed #00b1c2;
      .result--div--spacing {
        flex-direction: column;
        .result--first--section--grid {
          .result--first--section {
            .result--left--div {
              .result--number--section {
                .result--number--div {
                  .result--number {
                    font-size: 2rem;
                    width: 3.375rem;
                    height: 3.375rem;
                    line-height: 3.37rem;
                    margin-top: -0.5rem;
                    margin-left: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    color: #00b1c2;
                    border: 1px solid #27d1c9;
                    border-radius: 50%;
                  }
                }
              }
              .result--title--div {
                padding-left: 10px;
                padding-right: 10px;
              }
              .result--number--div::after,
              .result--number--div::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// mobile landscape

@media only screen and (min-device-width: 812px) and (max-device-width: 1024px) and (orientation: landscape) {
  body {
    .how--it--works--mobile--section {
      .order--kit--bg {
        border-bottom: none;
        .order--kit--div--spacing {
          flex-direction: row;
          .order--kit--first--section--grid {
            display: block;
            .order--kit--first--section {
              .order--kit--left--div {
                .order--kit--number--section {
                  .order--kit--number--div {
                    .order--kit--number {
                      font-size: 2.5rem;
                      width: 4.375rem;
                      height: 4.375rem;
                      line-height: 4.37rem;
                      margin-top: -0.5rem;
                      margin-left: -1rem;
                      display: block;
                      text-align: center;
                      font-weight: 300;
                      color: #00b1c2;
                      border: 1px solid #27d1c9;
                      border-radius: 50%;
                    }
                  }
                }
                .order--kit--title--div {
                  padding-left: 0;
                  padding-right: 0;
                }
                .order--kit--number--div::after {
                  display: block;
                }
              }
            }
          }
        }
      }

      .material--delivery--bg {
        border-bottom: none;
        .material--delivery--div--spacing {
          flex-direction: row;
          .material--delivery--first--section--grid {
            display: block;
            .material--delivery--first--section {
              .material--delivery--left--div {
                .material--delivery--number--section {
                  .material--delivery--number--div {
                    .material--delivery--number {
                      font-size: 2.5rem;
                      width: 4.375rem;
                      height: 4.375rem;
                      line-height: 4.37rem;
                      margin-top: -0.5rem;
                      margin-left: -1rem;
                      display: block;
                      text-align: center;
                      font-weight: 300;
                      color: #00b1c2;
                      border: 1px solid #27d1c9;
                      border-radius: 50%;
                    }
                  }
                }
                .material--delivery--title--div {
                  padding-left: 0;
                  padding-right: 0;
                }
                .material--delivery--number--div::after,
                .material--delivery--number--div::before {
                  display: block;
                }
              }
            }
          }
        }
      }
      .blood--sample--bg {
        border-bottom:none;
        .blood--sample--div--spacing {
          flex-direction: row;
          .blood--sample--first--section--grid {
            display: block;
            .blood--sample--first--section {
              .blood--sample--left--div {
                .blood--sample--number--section {
                  .blood--sample--number--div {
                    .blood--sample--number {
                      font-size: 2.5rem;
                      width: 4.375rem;
                      height: 4.375rem;
                      line-height: 4.37rem;
                      margin-top: -0.5rem;
                      margin-left: -1rem;
                      display: block;
                      text-align: center;
                      font-weight: 300;
                      color: #00b1c2;
                      border: 1px solid #27d1c9;
                      border-radius: 50%;
                    }
                  }
                }
                .blood--sample--title--div {
                  padding-left: 0;
                  padding-right: 0;
                }
                .blood--sample--number--div::after,
                .blood--sample--number--div::before {
                  display: block;
                }
              }
            }
          }
        }
      }
      .test--laboratory--bg {
        border-bottom: none;
        .test--laboratory--div--spacing {
          flex-direction: row;
          .test--laboratory--first--section--grid {
            display: block;
            .test--laboratory--first--section {
              .test--laboratory--left--div {
                .test--laboratory--number--section {
                  .test--laboratory--number--div {
                    .test--laboratory--number {
                      font-size: 2.5rem;
                      width: 4.375rem;
                      height: 4.375rem;
                      line-height: 4.37rem;
                      margin-top: -0.5rem;
                      margin-left: -1rem;
                      display: block;
                      text-align: center;
                      font-weight: 300;
                      color: #00b1c2;
                      border: 1px solid #27d1c9;
                      border-radius: 50%;
                    }
                  }
                }
                .test--laboratory--title--div {
                  padding-left: 0;
                  padding-right: 0;
                }
                .test--laboratory--number--div::after,
                .test--laboratory--number--div::before {
                  display: block;
                }
              }
            }
          }
        }
      }
      .result--bg {
        border-bottom: none;
        .result--div--spacing {
          flex-direction: row;
          .result--first--section--grid {
            display: block;
            .result--first--section {
              .result--left--div {
                .result--number--section {
                  .result--number--div {
                    .result--number {
                      font-size: 2.5rem;
                      width: 4.375rem;
                      height: 4.375rem;
                      line-height: 4.37rem;
                      margin-top: -0.5rem;
                      margin-left: -1rem;
                      display: block;
                      text-align: center;
                      font-weight: 300;
                      color: #00b1c2;
                      border: 1px solid #27d1c9;
                      border-radius: 50%;
                    }
                  }
                }
                .result--title--div {
                  padding-left: 0;
                  padding-right: 0;
                }
                .result--number--div::after,
                .result--number--div::before {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
// ipad landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (min-height: 768px) and (max-height: 786px) and (orientation: landscape) {
  body {
    .tab--order--kit--bg {
      border-bottom: none;
      .order--kit--div--spacing {
        flex-direction: row;
        .order--kit--first--section--grid {
          .order--kit--first--section {
            .order--kit--left--div {
              .order--kit--number--div::after {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--material--delivery--bg {
      border-bottom: none;
      .material--delivery--div--spacing {
        flex-direction: row;
        .material--delivery--first--section--grid {
          .material--delivery--first--section {
            .material--delivery--left--div {
              .material--delivery--number--div::after,
              .material--delivery--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--blood--sample--bg {
      border-bottom: none;
      .blood--sample--div--spacing {
        flex-direction: row;
        .blood--sample--first--section--grid {
          .blood--sample--first--section {
            .blood--sample--left--div {
              .blood--sample--number--div::after,
              .blood--sample--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--test--laboratory--bg {
      border-bottom: none;
      .test--laboratory--div--spacing {
        flex-direction: row;
        .test--laboratory--first--section--grid {
          .test--laboratory--first--section {
            .test--laboratory--left--div {
              .test--laboratory--number--div::after,
              .test--laboratory--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--result--bg {
      border-bottom: none;
      .result--div--spacing {
        flex-direction: row;
        .result--first--section--grid {
          .result--first--section {
            .result--left--div {
              .result--number--div::after,
              .result--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
//iPad Pro Portrait
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  body {
    .tab--order--kit--bg {
      border-bottom: none;
      .order--kit--div--spacing {
        flex-direction: row;
        .order--kit--first--section--grid {
          .order--kit--first--section {
            .order--kit--left--div {
              .order--kit--number--div::after {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--material--delivery--bg {
      border-bottom: none;
      .material--delivery--div--spacing {
        flex-direction: row;
        .material--delivery--first--section--grid {
          .material--delivery--first--section {
            .material--delivery--left--div {
              .material--delivery--number--div::after,
              .material--delivery--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--blood--sample--bg {
      border-bottom: none;
      .blood--sample--div--spacing {
        flex-direction: row;
        .blood--sample--first--section--grid {
          .blood--sample--first--section {
            .blood--sample--left--div {
              .blood--sample--number--div::after,
              .blood--sample--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--test--laboratory--bg {
      border-bottom: none;
      .test--laboratory--div--spacing {
        flex-direction: row;
        .test--laboratory--first--section--grid {
          .test--laboratory--first--section {
            .test--laboratory--left--div {
              .test--laboratory--number--div::after,
              .test--laboratory--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--result--bg {
      border-bottom: none;
      .result--div--spacing {
        flex-direction: row;
        .result--first--section--grid {
          .result--first--section {
            .result--left--div {
              .result--number--div::after,
              .result--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

// ipad pro landscape

@media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (min-height: 1024px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  body {
    .tab--order--kit--bg {
      border-bottom: none;
      .order--kit--div--spacing {
        flex-direction: row;
        .order--kit--first--section--grid {
          .order--kit--first--section {
            .order--kit--left--div {
              .order--kit--number--div::after {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--material--delivery--bg {
      border-bottom: none;
      .material--delivery--div--spacing {
        flex-direction: row;
        .material--delivery--first--section--grid {
          .material--delivery--first--section {
            .material--delivery--left--div {
              .material--delivery--number--div::after,
              .material--delivery--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--blood--sample--bg {
      border-bottom: none;
      .blood--sample--div--spacing {
        flex-direction: row;
        .blood--sample--first--section--grid {
          .blood--sample--first--section {
            .blood--sample--left--div {
              .blood--sample--number--div::after,
              .blood--sample--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--test--laboratory--bg {
      border-bottom: none;
      .test--laboratory--div--spacing {
        flex-direction: row;
        .test--laboratory--first--section--grid {
          .test--laboratory--first--section {
            .test--laboratory--left--div {
              .test--laboratory--number--div::after,
              .test--laboratory--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab--result--bg {
      border-bottom: none;
      .result--div--spacing {
        flex-direction: row;
        .result--first--section--grid {
          .result--first--section {
            .result--left--div {
              .result--number--div::after,
              .result--number--div::before {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
