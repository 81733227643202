/*!

=========================================================
* Material Kit PRO React - v1.8.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";

@font-face {
  font-family: Raleway-Medium;
  src        : url(../fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src        : url(../fonts/Raleway-SemiBold.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway-SemiBold !important;
}

body::-webkit-scrollbar {
  display: none;
}

.btn-spinner {
  border       : 3px solid #fff;
  height       : 20px;
  width        : 20px;
  border-radius: 50%;
  border-top   : 5px solid transparent;
  animation    : load-animate infinite linear 1.2s;
}

.btn-spinner-dark {
  border       : 3px solid #0000007a;
  height       : 15px;
  width        : 15px;
  border-radius: 50%;
  border-top   : 5px solid transparent;
  animation    : load-animate infinite linear 1.2s;
}

@keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform        : rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform        : rotate(180deg);
    opacity          : 0.35;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform        : rotate(360deg);
  }
}

// scss for login, sign up, self-assessment start

body {
  .login--main--div {
    background: -webkit-linear-gradient(right,
        #045a68 0%,
        #08525edb 50%,
        #0097a7cc 100%) !important;

    .login--main--container {
      box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
        0 7px 10px 7px rgba(0, 0, 0, 0.15);
      border          : 0px solid #0000001f;
      border-radius   : 18px;
      min-height      : 65vh;
      display         : flex;
      justify-content : center;
      align-items     : center;
      background-color: #fff;
      overflow        : hidden;

      .login--left--img--grid.login--tab--left--img--grid {
        clip-path    : none;
        margin-bottom: 20px;

        .login--left--img {
          height    : 100%;
          min-height: 45vh;
        }
      }

      .login--left--img--grid {
        padding         : 0;
        background-color: #f7f7f7;
        clip-path       : polygon(0 0%, 90% 0, 100% 100%, 0 100%);

        .login--left--img {
          min-height     : 65vh;
          display        : flex;
          justify-content: center;
          align-items    : center;

          .lazy-load-image-background {
            height: 370px;
            width : 80%;

            img {
              height    : 100%;
              width     : 100%;
              margin-top: 0;
            }
          }
        }
      }

      .login--right--section--grid {
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;

        .login--main--title {
          line-height  : normal;
          margin-top   : 0;
          margin-bottom: 0;
          font-size    : 30px;
          font-weight  : bold;
          color        : #006978 !important;
        }

        .login--main--content {
          margin-top   : 20px;
          margin-bottom: 0;
          box-shadow   : none;

          .login--email--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .login--password--div {
            .MuiTextField-root {
              margin: 0;
            }
          }

          .signup--name--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .signup--email--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .signup--password--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .signup--confirm--password--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .signup--phone--div {
            padding-top: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .assessment--name--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .assessment--email--div {
            margin-bottom: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .assessment--phone--div {
            padding-top: 15px;

            .MuiTextField-root {
              margin: 0;
            }
          }

          .login--btn--div {
            button {
              margin: 30px 0;
            }
          }
        }

        .sign--up--section {
          margin-bottom: 3px;

          span {
            font-size: 15px;

            .sing--up--link {
              margin-left: 5px;

              span {
                text-decoration: underline;

                &:hover {
                  color: #006978;
                }
              }
            }
          }
        }

        .login--option--div {
          display        : flex;
          align-items    : center;
          justify-content: center;

          p {
            font-size    : 15px;
            margin-bottom: 0;

            .or--login--with--text {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.login--signup--tab--section {
  .login--main--div {
    padding-top: 95px;
  }
}

// login, signup, self assessment tablet css start
body {
  .sign--up--tab--header {
    header {
      margin-bottom: 0;
    }
  }
}

// login, signup, self assessment tablet css end

// login, signup, self assessment mobile css start
body {
  .sign--up--mobile--header {
    header {
      margin-bottom: 0;
    }
  }

  .login--mobile--main--div.login--main--div {
    padding-top: 58px;

    .login--main--container {
      margin       : 30px 10px;
      border-radius: 5px;

      .login--left--img--grid {
        clip-path    : none;
        margin-bottom: 15px;

        .login--left--img {
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

// login, signup, self assessment mobile css end

// scss for login, sign up, self-assessment end

// mobile landscape media query

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  body {
    .login--mobile--main--div.login--main--div {

      .login--main--container {
        margin-top        : 30px;
        margin-bottom     : 30px;
        border-radius     : 18px;
        // .login--left--img--grid
        // {
        //   margin-bottom: 15px;
        // }
      }
    }
  }
}

// ipad landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (min-height: 768px) and (max-height: 786px) and (orientation: landscape) {
  body {
    .login--main--div {
      padding-top: 95px;

      .login--main--container {
        min-height: 75vh;

        .login--left--img--grid.login--tab--left--img--grid {
          clip-path    : polygon(0 0%, 90% 0, 100% 100%, 0 100%);
          margin-bottom: 0;

          .login--left--img {
            height    : 100%;
            min-height: 75vh;
          }
        }
      }
    }
  }
}

//iPad Pro Portrait
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  body {
    .login--main--div {
      padding-top: 95px;

      .login--main--container {
        min-height: 45vh;

        .login--left--img--grid.login--tab--left--img--grid {
          clip-path    : polygon(0 0%, 90% 0, 100% 100%, 0 100%);
          margin-bottom: 0;
        }

        .login--left--img--grid {
          .login--left--img {
            min-height: 45vh;
          }
        }
      }
    }
  }
}

// ipad pro landscape

@media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (min-height: 1024px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  body {
    .login--main--div {
      .login--main--container {
        min-height: 65vh;

        .login--left--img--grid.login--tab--left--img--grid {
          clip-path    : polygon(0 0%, 90% 0, 100% 100%, 0 100%);
          margin-bottom: 0;

          .login--left--img {
            min-height: 65vh;
          }
        }
      }
    }
  }
}

.MuiFormLabel-root.Mui-focused {
  color: #006978 !important;
}