.blog--page {
  padding-top: 1px;
  .blog--main--title {
    font-weight: bold;
    color: #006978;
  }
  .blog--main--section {
    display: flex;
    margin-bottom: 50px;
    .blog--main--left--section {
      .blog--main--detail {
        .blog--main--img--section {
          overflow: hidden;
          .lazy-load-image-background {
            height: 200px;
            width: 100%;
            transform: translate(0, 0);
            transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
            box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
              0 4px 25px 0 rgba(0, 0, 0, 0.12),
              0 8px 10px -5px rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 3px;
              transition: 1s ease-out;
            }
          }
        }
        .blog--main--img--section:hover {
          .lazy-load-image-background {
            opacity: 0.75;
            img {
              transform: scale3d(1.1, 1.1, 1);
              transition: 1s ease-out;
            }
          }
        }
      }
    }
    .blog--main--right--section {
      .blog--title--name {
        span {
          transition: 0.3s ease;
          color: #006878;
          font-weight: 500;
        }
      }
      .blog--title {
        margin: 8px 0;
        span {
          font-size: 25px;
          font-weight: 500;
        }
      }
      .blog--desc {
        color: #999999;
      }     
    }
  }
  .blog--main--section:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .blog--mobile--main--section {
    flex-direction: column;
    height: 100%;
    .blog--main--detail {
      margin-bottom: 20px;
      .blog--main--img--section {
        .lazy-load-image-background {
        }
      }
    }
  }
  .blog--main--section.blog--mobile--main--section:nth-child(2n) {
    flex-direction: column;
  }
}

.blog--detail--page--spacing {
    background-image: url("../../assets/img/blog/blog-bg.jpg") !important;
  padding-top: 1px;
  .blog--detail--page {
    .blog--detail--main--title {
      font-weight: bold;
      color: #006978;
    }
  }
}
