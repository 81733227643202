.sectionStyle {
    padding            : 150px 0px;
    background-size    : cover;
    background-position: 50%;

    .divWidth {
        width : 80%;
        margin: auto;
    }

    .divBgStyle {
        padding         : 30px 50px 50px 50px;
        background-color: white;
        box-shadow      : 1px 1px 8px 0px #00000061;
    }

    .fontStyle {
        font-weight   : bold;
        color         : #3c4858;
        text-transform: uppercase;
        font-weight   : 700;
        min-height    : 32px;
        font-size     : 2.2rem;
    }

    .description {
        color      : #718792;
        font-size  : 1.15rem;
        line-height: 1.5em;
    }

    .divSymptoms {
        display        : flex;
        justify-content: space-evenly;
        text-align     : center;
        margin-top     : 30px;

        img {
            margin-top: 25px;
            height    : 100px;
            width     : 100px;
        }

        p {
            margin-top : 10px;
            font-size  : 1.2rem;
            font-weight: 500;
        }
    }

    .symptomsContent {
        padding-left : 20px;
        margin-right : 40px;
        margin-bottom: 0px;
    }

}


.footer-form .MuiInput-underline:after,
.footer-form .MuiInput-underline:before {
    border-bottom: 2px solid #a7d5dc !important;
}

.footer-form .MuiInputBase-root,
.footer-form .MuiFormLabel-root {
    color: #fff !important
}

.mb-10 {
    margin-bottom: 10px;
}

.package li {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
    text-align     : left !important;
    border-bottom  : 1px solid #d3d3d3 !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: #455a641f !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #455a64 !important;
}

.CircularProgressbar {
    height: 150px;
}

.descriptionPackage {
    color: #718792;
}

.sectionCardButtonRight {
    width     : 100%;
    text-align: right;
}

.sectionCardButtonCenter {
    width     : 100%;
    text-align: center;
}

.contactUsForm,
.promoCodeDiv {
    .MuiFormLabel-root.Mui-focused {
        color: #0097a7 !important;
    }

    .MuiInput-underline {
        &:after {
            border-bottom: 2px solid #0097a7 !important
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #0097a7;
    }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0097a7 !important;
}

.MuiInput-underline {
    &:after {
        border-bottom: 2px solid #0097a7 !important
    }
}

.fixed {
    position  : fixed;
    top       : 120px;
    width     : 100%;
    left      : 0;
    z-index   : 1120;
    box-shadow: 0px 8px 8px 0px #0003 !important;
    border-top: 1px solid #d3d3d3;
}

.selfAssessmentForm {
    .Mui-focused {
        color: #1c313a !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1c313a !important;
    }
}

.questionnaire {
    .MuiRadio-colorPrimary.Mui-checked {
        color: #0097a7;
    }

}

.customSnackbar {
    top     : 0 !important;
    right   : 0 !important;
    position: absolute !important;
}

.dialogPadding {
    padding  : 40px 50px !important;
    min-width: 250px;
}

.checkAssessmentForm {
    .MuiDialogTitle-root {
        background: rgb(0, 105, 120);
        color     : white;
    }
}


@media only screen and (max-width: 768px) {
    .sectionStyle {
        .divWidth {
            width: 80%
        }
    }

    .fixed {
        top: 50px;
    }

    .dialogPadding {
        padding  : 30px !important;
        min-width: 250px;
    }
}

@media only screen and (max-width: 1030px) {
    .descriptionWidth {
        width: 70%;
    }
}

.active .bs-stepper-circle {
    background-color: #006978 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #006978 !important;
    ;
}

.question-stepper-title {
    // text-align   : center;
    margin-bottom: 20px;
    font-size    : 26px;
}

.radio-content {
    .MuiFormControlLabel-root {
        padding      : 10px 20px;
        box-shadow   : 2px 3px 15px #00000030;
        margin-bottom: 20px;
        margin-left  : 0 !important;
        margin-right : 0 !important;
    }

    .MuiFormControl-root {
        width: 100% !important;
    }
}

.symptomsList {
    margin-bottom: 5px;
    // margin-left  : 20px;
    padding-left: 10px;

    li {
        font-size  : 1.1rem;
        line-height: 40px;
        font-weight: 400;
        list-style : none;
    }
}

.symptomsDesc {
    // text-align : center;
    font-size  : 1.1rem;
    font-weight: 400;
}

.text-center {
    text-align: center;
}

.mt-50 {
    margin-top: 50px;
}

.div-content {
    padding   : 10px 20px;
    box-shadow: 2px 3px 15px #00000030;
}

.StripeCheckout {
    box-shadow: none !important;
    padding   : 10px !important;
    width     : 100%;

    span {
        background-image: none !important;
        box-shadow      : none !important;
        text-shadow     : none !important;
    }
}